"use strict";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import { Map } from "immutable";
import { SCREENING_CATEGORY_ID, SPORTS_GROUP_ID, truncate } from "../helpers/constants";
import { generateEventLink, generateAbsoluteUrl } from "../helpers/linkGenerators";
import { safeJsonLdReplacer } from "../helpers/safeJsonLdReplace";
import { checkValidEvent } from "../helpers/misc/recentlyViewedHelpers";
const getMetadataForEventHelmet = eventData => {
    let summary = eventData.get("meta_description", "") || eventData.get("description", "");
    summary = summary.replace(/<\/?[^>]+(>|$)/g, "");
    summary = truncate(summary, 160, "...");
    const eventName = eventData.get("name", "").replace(/<\/?[^>]+(>|$)/g, "");
    const keywords = `${eventName},${eventData.getIn(["category_id", "name"])}`;
    const eventTitle = eventData.get("is_free") ? `RSVP to ${eventName}` : `Book tickets to ${eventName}`;
    const title = (eventData.get("seo_title") || "").replace(/<\/?[^>]+(>|$)/g, "") || eventTitle;
    const isScreeningEvent = eventData.getIn(["category_id", "id"]) === SCREENING_CATEGORY_ID;
    const isExpiredEvent = !eventData.get("venue") || eventData.getIn(["venue", "shows"]).size === 0;
    const isSearchable = eventData.get("searchable", false);
    const metadata = {
        metadata: [
            { name: "description", content: summary },
            { name: "keywords", content: keywords },
            { name: "event_id", content: eventData.get("_id") },
            { property: "og:title", content: eventName },
            { property: "og:description", content: summary },
            { property: "og:image", content: eventData.get("horizontal_cover_image") },
            { property: "og:image:width", content: "658" },
            { property: "og:image:height", content: "360" },
            { property: "twitter:card", content: "summary_large_image" },
            { property: "twitter:site", content: "@paytminsider" },
            { property: "twitter:title", content: eventName },
            { property: "twitter:description", content: summary },
            { property: "twitter:image", content: eventData.get("horizontal_cover_image") }
        ],
        title
    };
    if (isScreeningEvent || isExpiredEvent || !isSearchable) {
        metadata.metadata.push({ name: "robots", content: "noindex,nofollow" });
    }
    return metadata;
};
const getAvailability = (item_state, quantity_available) => {
    if (item_state === "available" && quantity_available) {
        return "http://schema.org/InStock";
    }
    else {
        return "http://schema.org/SoldOut";
    }
};
const getEventLocationAndStatus = (eventData, eventSlug) => {
    const eventStatus = eventData.get("isDigitalEvent") ? "EventMovedOnline" : "EventScheduled";
    if (eventStatus === "EventMovedOnline") {
        return {
            eventStatus,
            eventAttendanceMode: "OnlineEventAttendanceMode",
            location: {
                "@type": "VirtualLocation",
                url: eventSlug
            }
        };
    }
    return {
        eventStatus,
        eventAttendanceMode: "OfflineEventAttendanceMode",
        location: {
            "@type": "Place",
            name: eventData.getIn(["venue", "name"]),
            address: eventData.getIn(["venue", "id", "address"]),
            geo: {
                "@type": "GeoCoordinates",
                latitude: eventData.getIn(["venue", "id", "geolocation", "latitude"]),
                longitude: eventData.getIn(["venue", "id", "geolocation", "longitude"])
            }
        }
    };
};
const getSchemaForEventHelmet = eventData => {
    const eventSlug = generateAbsoluteUrl(generateEventLink(eventData.get("slug")));
    const { eventStatus, location, eventAttendanceMode } = getEventLocationAndStatus(eventData, eventSlug);
    let eventSchema = new Map({
        "@context": "http://schema.org",
        "@type": "Event",
        name: eventData.get("name", "").replace(/<\/?[^>]+(>|$)/g, ""),
        description: eventData.get("description", "").replace(/<\/?[^>]+(>|$)/g, ""),
        url: eventSlug,
        image: eventData.get("horizontal_cover_image", ""),
        eventStatus,
        location,
        eventAttendanceMode
    });
    if (eventData.has("brand_id")) {
        eventSchema = eventSchema.withMutations(ev => ev.set("organizer", {
            "@type": "Organization",
            name: eventData.getIn(["brand_id", "name"])
        }));
    }
    const extraInfoArray = JSON.parse(JSON.stringify(eventData.get("extra_info", [])));
    if (eventData.has("extra_info")) {
        const extraInfoArray = JSON.parse(JSON.stringify(eventData.get("extra_info", [])));
        const languageObject = extraInfoArray.find(item => item.title === "Language");
        const dateRangeObject = extraInfoArray.find(item => item.title === "For Age(s)");
        if (languageObject) {
            eventSchema = eventSchema.withMutations(ev => ev.set("inLanguage", {
                "@type": "Language",
                language: languageObject.text
            }));
        }
        if (dateRangeObject) {
            eventSchema = eventSchema.withMutations(ev => ev.set("typicalAgeRange", {
                "@type": "Age-Range",
                language: dateRangeObject.text
            }));
        }
    }
    if (eventData.has("name") && eventData.has("category_id")) {
        const eventName = eventData.get("name").replace(/<\/?[^>]+(>|$)/g, "");
        const keywords = `${eventName},${eventData.getIn(["category_id", "name"], "")}`;
        eventSchema = eventSchema.withMutations(ev => ev.set("keywords", {
            "@type": "Description",
            content: keywords
        }));
    }
    // one event schema script per show
    const offersList = eventData.getIn(["venue", "shows"], []).map(show => {
        const startDate = new Date(show.get("start_utc_timestamp") * 1000).toISOString();
        const endDate = new Date(show.get("end_utc_timestamp") * 1000).toISOString();
        const allItems = show
            .get("items_for_sale")
            .map(ifs => ifs.get("items"))
            .flatten(1);
        const highPriceItem = allItems.maxBy(i => i.get("price"));
        const highPrice = highPriceItem ? highPriceItem.get("price") : 0;
        const lowPriceItem = allItems.minBy(i => i.get("price"));
        const lowPrice = lowPriceItem ? lowPriceItem.get("price") : 0;
        const offers = allItems
            .filter(item => item.get("is_hidden") === false)
            .filter(item => item.get("restrictions").includes("*") || item.get("restrictions").includes("online"))
            .map(item => {
            let offer = {
                "@type": "Offer",
                availabilityEnds: item.get("availability_date"),
                url: eventSlug,
                name: item.get("name"),
                availability: getAvailability(item.get("item_state"), item.get("quantity_available_for_purchase")),
                price: item.get("price"),
                priceCurrency: "INR"
            };
            if (eventData.has("published_at_timestamp")) {
                offer.validFrom = eventData.get("published_at_timestamp");
            }
            return offer;
        });
        return eventSchema.withMutations(ev => ev
            .set("startDate", startDate)
            .set("endDate", endDate)
            .set("offers", new Map({
            "@type": "AggregateOffer",
            lowPrice: lowPrice,
            highPrice: highPrice,
            offerCount: offers.size,
            offers: offers,
            url: eventSlug
        })));
    });
    return offersList.toJS();
};
const attachSnapPixelForOnePlus = eventData => {
    if (eventData.get("slug") === "oneplus-music-festival-nov16-2019") {
        return (<script type="text/javascript">
				{`
				(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
			{a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
				a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
				r.src=n;var u=t.getElementsByTagName(s)[0];
				u.parentNode.insertBefore(r,u);})(window,document,
				'https://sc-static.net/scevent.min.js');
				snaptr('init', 'd92fca3a-70fc-49ed-ac02-bdbbaebc7e3b', {
				'user_email': '__INSERT_USER_EMAIL__'
			});
				snaptr('track', 'PAGE_VIEW');`}
			</script>);
    }
};
const addQueueScript = eventData => {
    if (eventData.get("queue_configuration") &&
        eventData.getIn(["queue_configuration", "is_active"]) &&
        checkValidEvent(eventData)) {
        return (<script data-queue-fair-client="paytminsider" src="https://files.queue-fair.net/queue-fair-adapter.js"></script>);
    }
};
const EventHelmet = props => {
    const eventSchema = getSchemaForEventHelmet(props.eventData);
    const { title, metadata } = getMetadataForEventHelmet(props.eventData);
    return (<Helmet>
			<title>{title}</title>
			{metadata.map((tag, i) => {
            return <meta {...tag} key={i}/>;
        })}
			{eventSchema.map((schema, i) => {
            return (<script type="application/ld+json" key={i}>
						{JSON.stringify(schema, safeJsonLdReplacer, 4)}
					</script>);
        })}
			{attachSnapPixelForOnePlus(props.eventData)}
			{addQueueScript(props.eventData)}
		</Helmet>);
};
EventHelmet.propTypes = {
    eventData: PropTypes.object
};
export default EventHelmet;
