/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useState, useRef, useEffect } from "react";
import { getFont, FontColor, flexBox, ellipsisLine } from "../ui-lib/Atoms/Typography/stylesUtils";
import { ArrowDownIcon } from "../ui/icons/ArrowDownIcon";
import { ArrowUpIcon } from "../ui/icons/ArrowUpIcon";
import { getSanitisedHtml } from "../../helpers/misc";
import { sanitizeHtml } from "../../helpers/misc/sanitizeHtml";
const EventAboutReadMore = ({ content, limit = 300, showReadMore = true, customStyle = '' }) => {
    const [showAll, setShowAll] = useState(false);
    const [shouldShorten, setShouldShorten] = useState(false);
    const contentRef = useRef(null);
    const normalizeString = content;
    useEffect(() => {
        if (contentRef.current) {
            const lineHeight = parseFloat(getComputedStyle(contentRef.current).lineHeight);
            const maxLines = 4;
            const maxHeight = lineHeight * maxLines;
            if (contentRef.current.scrollHeight > maxHeight || normalizeString.length > limit) {
                setShouldShorten(true);
            }
        }
    }, [normalizeString, limit]);
    return (<div>
            <div ref={contentRef} css={css([showAllStyle(showAll, showReadMore), customStyle])} dangerouslySetInnerHTML={{ __html: sanitizeHtml(getSanitisedHtml(normalizeString)) }}/>
            {shouldShorten && <div css={css([showMoreCont])} onClick={() => setShowAll(!showAll)}>
                <p>{!showAll ? 'Show more' : 'Show less'}</p>
                {!showAll ? <ArrowDownIcon /> : <ArrowUpIcon />}
            </div>}
        </div>);
};
export default EventAboutReadMore;
const showAllStyle = (doEllipsis, showReadMore) => `
    word-break: normal;
    overflow-wrap: break-word;
    white-space: normal;
    ${getFont('16px', 400)}
    ${FontColor('--Grey-Regular-NewCopy')}
    ${(!doEllipsis && showReadMore) ? ellipsisLine('4') : ''}
    i {
        font-style: normal;
    }
    h1, h2, h3, h4, h5, h6 {
        font-size: 15px;
    }
`;
const showMoreCont = `
    margin-top: 15px;
    ${flexBox('flex-start', 'center')}
    gap: 4px;
    img, svg {
        width: 14px;
        height: 14px;
    }
    p {
        ${getFont('14px', 600)}
        ${FontColor('--grey-highlight-copy')}
    }
`;
