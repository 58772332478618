/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import classNames from "classnames";
import { imageContainer } from "../styles/modules/imageStyles";
import { sanitizeHtml } from "../helpers/misc/sanitizeHtml";
const imageContainerParent = `
	section.image {
		${imageContainer}
	}
	figure.image {
		${imageContainer}
	}
`;
const isImageSection = (htmlString) => {
    return /<figure|section.*class=".*image.*".*>/.test(htmlString);
};
const RichTextDisplay = (props) => {
    const alignClass = props.data.getIn(["metadata", "align"]) ? `text-${props.data.getIn(["metadata", "align"])}` : "";
    const marginClass = props.data.getIn(["metadata", "left_margin"])
        ? `text-margin-${props.data.getIn(["metadata", "left_margin"])}`
        : "";
    const textClassName = classNames("text", alignClass, marginClass, props.metaClass);
    const cssStyle = props.cssStyle;
    const addImageContainerStyles = isImageSection(props.data.get("content"));
    return (<section dangerouslySetInnerHTML={{ __html: sanitizeHtml(props.data.get("content")) }} className={textClassName} css={[cssStyle, addImageContainerStyles && css(imageContainerParent)]}/>);
};
export default RichTextDisplay;
