/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";
import React from "react";
import { headerSummaryStyles, largeHeaderDetail, largeHeaderDetailContainer, largeHeaderSummaryStyles, largeHeaderTitle, largeVisualCard, visualCard } from "../styles/modules/headerCards";
import GenreStrip from "./GenreStrip";
import { sanitizeHtml } from "../helpers/misc/sanitizeHtml";
const LargeSummaryCard = props => {
    const { header, headerVisual, headerDetail, headerTitle, headerSummary, categoryName, categoryColour, title, summary, slug } = props;
    return (<section className={header}>
			<div css={css(largeVisualCard)} className={headerVisual}>
				{props.headerImageOrVideo}
			</div>
			<div css={css(largeHeaderDetailContainer)}>
				<div css={css(largeHeaderDetail)} className={headerDetail}>
					<GenreStrip categoryName={categoryName} categoryColour={categoryColour}/>
					<h1 css={css(largeHeaderTitle)}>{title}</h1>
					<div className="header-action-container">
						<div css={css([headerSummaryStyles, largeHeaderSummaryStyles])} className={headerSummary} dangerouslySetInnerHTML={{ __html: sanitizeHtml(summary) }}/>
						{props.actionButton}
					</div>
				</div>
				{props.shareButtons}
			</div>
		</section>);
};
LargeSummaryCard.propTypes = {
    header: PropTypes.string.isRequired,
    headerVisual: PropTypes.string.isRequired,
    headerDetail: PropTypes.string.isRequired,
    headerTitle: PropTypes.string.isRequired,
    headerSummary: PropTypes.string.isRequired,
    categoryName: PropTypes.string.isRequired,
    categoryColour: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    headerImageOrVideo: PropTypes.node,
    actionButton: PropTypes.node,
    shareButtons: PropTypes.node
};
LargeSummaryCard.defaultProps = {
    categoryColour: ""
};
export default LargeSummaryCard;
